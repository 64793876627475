<script>
import {
  Pie, Doughnut, mixins,
} from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  extends: Pie,
  mixins: [reactiveProp],
  data() {
    return {
      options: {
        legend: {
          position: 'bottom',
          align: 'start',
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  async mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
