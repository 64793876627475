<template>
  <div class="page_content page_content_home">
    <div class="content_header d-flex align-items-center">
      <span>Dashboard</span>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-2">
            <div class="search_section d-flex align-items-center">
              <div class="form-group d-flex align-items-center">
                <select
                  v-model="search.system_code"
                  class="form-control form_input"
                  @change="get_dashboard()"
                >
                  <option
                    :value="'ALL'"
                  >
                    不限
                  </option>
                  <option
                    v-for="item in system"
                    :key="item.systemCode"
                    :value="item.systemCode"
                  >
                    {{ item.systemName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-10">
            <div class="search_section d-flex flex-wrap align-items-center justify-content-lg-end">
              <div class="form-group d-flex align-items-center">
                <div>
                  <input
                    id="saerch_dateStart"
                    v-model="search.dateStart"
                    v-validate="'required'"
                    type="date"
                    name="起始日期"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('search.saerch_dateStart')}"
                    data-vv-scope="search"
                    placeholder="選擇日期"
                  >
                </div>
              </div>
              <div class="form-group d-flex align-items-center mx-2">
                <span>~</span>
              </div>
              <div class="form-group d-flex align-items-center">
                <div>
                  <input
                    id="saerch_dateEnd"
                    v-model="search.dateEnd"
                    v-validate="'required'"
                    type="date"
                    name="結束日期"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('search.saerch_dateEnd')}"
                    data-vv-scope="search"
                    placeholder="選擇日期"
                  >
                </div>
              </div>
              <div class="form-group d-flex align-items-center">
                <a
                  href="#"
                  class="search_btn d-flex align-items-center justify-content-center rounded px-2"
                  @click.prevent="get_dashboard()"
                >
                  套用
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex">
            <div
              class="btn-group mb-2 ml-auto"
              role="group"
            >
              <button
                v-for="item in time"
                :key="item.date"
                type="button"
                class="btn btn_outline_primary px-2"
                :class="{ 'btn_active': search.mode === item.date }"
                @click.prevent="search.mode = item.date, get_dashboard()"
              >
                {{ item.name }}
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <div class="small-box">
            <div class="inner">
              <h3 v-if="dashboard.ordersMember >= 0">
                {{ dashboard.ordersMember | currency }}
              </h3>
              <p>新訂購數</p>
            </div>
          </div>
          <div class="small-box">
            <div class="inner">
              <h3 v-if="dashboard.memberNew >= 0">
                {{ dashboard.memberNew | currency }}
              </h3>
              <p>註冊</p>
            </div>
          </div>
          <div class="small-box">
            <div class="inner">
              <h3 v-if="dashboard.memberTotal >= 0">
                {{ dashboard.memberTotal | currency }}
              </h3>
              <p>總會員數</p>
            </div>
          </div>
          <div class="small-box">
            <div class="inner">
              <h3 v-if="dashboard.orders >= 0">
                {{ dashboard.orders | currency }}
              </h3>
              <p>訂單數</p>
            </div>
          </div>
          <div class="small-box">
            <div class="inner">
              <h3 v-if="dashboard.ordersAmount >= 0">
                {{ dashboard.ordersAmount | currency }}
              </h3>
              <p>訂單總額</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card_header d-flex justify-content-between align-items-center">
                <h3 class="card_title">
                  業績
                </h3>
                <router-link
                  to="/dashboard/performance"
                  class="text-color_primary"
                >
                  查看更多
                </router-link>
              </div>
              <div class="card_body">
                <ChartComponent
                  v-if="loaded"
                  :chart-data="filter_diff_performance"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-8">
            <div class="card">
              <div class="card_header d-flex justify-content-between align-items-center">
                <h3 class="card_title">
                  會員
                </h3>
                <router-link
                  to="/dashboard/member"
                  class="text-color_primary"
                >
                  查看更多
                </router-link>
              </div>
              <div class="card_body">
                <ChartComponent
                  v-if="loaded"
                  :chart-data="filter_diff_member"
                />
              </div>
            </div>
            <div class="card">
              <div class="card_header d-flex justify-content-between align-items-center">
                <h3 class="card_title">
                  訂單數量
                </h3>
              </div>
              <div class="card_body">
                <ChartComponent
                  v-if="loaded"
                  :chart-data="filter_diff_order"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="card">
              <div class="card_header d-flex justify-content-between align-items-center">
                <h3 class="card_title">
                  銷售地區
                </h3>
                <router-link
                  to="/dashboard/member"
                  class="text-color_primary"
                >
                  查看更多
                </router-link>
              </div>
              <div class="card_body">
                <PieComponent
                  v-if="loaded && filter_percent_location.labels.length !== 0"
                  :chart-data="filter_percent_location"
                />
                <div class="table-responsive mt-3">
                  <table class="table table-borderless table-hover">
                    <thead class="">
                      <tr>
                        <th>縣市</th>
                        <th class="text-right">
                          佔比
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="filter_percent_location.labels.length === 0">
                        <tr>
                          <td>無</td>
                          <td />
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          v-for="location in filter_percent_location.all_data"
                          :key="location.location"
                        >
                          <td>{{ location.location }}</td>
                          <th class="text-right">
                            {{ location.percentage }}%
                          </th>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="card">
              <div class="card_header d-flex justify-content-between align-items-center">
                <h3 class="card_title">
                  進行活動
                </h3>
                <router-link
                  to="/dashboard/saleevent"
                  class="text-color_primary"
                >
                  查看更多
                </router-link>
              </div>
              <div class="card_body">
                <div class="table-responsive">
                  <table class="table table-borderless table-hover">
                    <thead>
                      <tr>
                        <th>活動名稱</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="filter_saleevent_list.length === 0">
                        <tr>
                          <td>無</td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          v-for="saleevent in filter_saleevent_list"
                          :key="saleevent.saleEventName"
                        >
                          <td>{{ saleevent.saleEventName }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="card">
              <div class="card_header d-flex justify-content-between align-items-center">
                <h3 class="card_title">
                  優惠碼
                </h3>
                <router-link
                  to="/dashboard/couponcode"
                  class="text-color_primary"
                >
                  查看更多
                </router-link>
              </div>
              <div class="card_body">
                <div class="table-responsive">
                  <table class="table table-borderless table-hover">
                    <thead>
                      <tr>
                        <th>優惠名稱</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="filter_couponcode_list.length === 0">
                        <tr>
                          <td>無</td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          v-for="couponcode in filter_couponcode_list"
                          :key="couponcode.couponName"
                        >
                          <td>{{ couponcode.couponName }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="card">
              <div class="card_header d-flex justify-content-between align-items-center">
                <h3 class="card_title">
                  銷售榜單
                </h3>
                <!-- <router-link
                  to="/dashboard"
                  class="text-color_primary"
                >
                  查看更多
                </router-link> -->
              </div>
              <div class="card_body">
                <div class="table-responsive">
                  <table class="table table-borderless table-hover">
                    <thead class="">
                      <tr>
                        <th>商品</th>
                        <th class="text-right">
                          業績
                        </th>
                        <th class="text-right">
                          成長率
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="filter_leader_board.all_data.length === 0">
                        <tr>
                          <td>無</td>
                          <td />
                          <td />
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          v-for="(item, index) in filter_leader_board.all_data"
                          :key="index"
                        >
                          <td>{{ item.prodName }}</td>
                          <td class="text-right">
                            {{ item.amount | currency }}
                          </td>
                          <td class="text-right">
                            {{ item.growthRate }}%
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="card">
              <div class="card_header d-flex justify-content-between align-items-center">
                <h3 class="card_title">
                  類別佔比
                </h3>
                <!-- <router-link
                  to="/dashboard"
                  class="text-color_primary"
                >
                  查看更多
                </router-link> -->
              </div>
              <div class="card_body">
                <PieComponent
                  v-if="loaded && filter_percent_category.labels.length !== 0"
                  :chart-data="filter_percent_category"
                />
                <div class="table-responsive mt-3">
                  <table class="table table-borderless table-hover">
                    <thead class="">
                      <tr>
                        <th>商品</th>
                        <th class="text-right">
                          業績
                        </th>
                        <th class="text-right">
                          佔比
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="filter_percent_category.labels.length === 0">
                        <tr>
                          <td>無</td>
                          <td />
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          v-for="(item, index) in filter_percent_category.all_data"
                          :key="index"
                        >
                          <td>{{ item.category }}</td>
                          <td class="text-right">
                            {{ item.amount | currency }}
                          </td>
                          <td class="text-right">
                            {{ item.percentage }}%
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ChartComponent from '@/components/Chart.vue';
import PieComponent from '@/components/Pie.vue';
import {
  API_GET_DASHBOARD,
} from '@/plugins/api';

export default {
  components: {
    ChartComponent,
    PieComponent,
  },
  data() {
    return {
      loaded: false,
      search: {
        system_code: 'ALL',
        mode: 'day',
        dateStart: new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * parseInt(7, 10))).toISOString().substr(0, 10),
        dateEnd: new Date().toISOString().substr(0, 10),
      },
      time: [
        {
          date: 'day',
          name: '天',
        },
        {
          date: 'week',
          name: '週',
        },
        {
          date: 'month',
          name: '月',
        },
      ],
      dashboard: {
        eventCoupon: [],
        eventSales: [],
        leaderBoardProduct: [],
        percentLocation: {},
        percentCategory: {},
        diffPerformance: {},
        diffMember: {},
        diffOrder: {},
      },
      color: ['#9b785099', '#fad18e99', '#f15f5f99', '#63ef4399', '#4374ef99', '#8543ef99', '#ef43e699', '#43efe399', '#30c18c99', '#6130c199'],
    };
  },
  computed: {
    ...mapGetters('system', ['system']),
    filter_diff_performance() {
      const vm = this;
      const { diffPerformance } = vm.dashboard;
      const data = {
        labels: [],
        datasets: [],
      };
      if (diffPerformance.labels && diffPerformance.labels.length > 0) {
        switch (vm.search.mode) {
          case 'month':
            diffPerformance.labels.forEach((label, index) => {
              const month = label.slice(3);
              label = `${month}月`;
              data.labels.push(label);
            });
            break;
          case 'week':
            diffPerformance.labels.forEach((label, index) => {
              const week = label.slice(1, 2);
              label = `第${week}週`;
              data.labels.push(label);
            });
            break;
          case 'day':
            diffPerformance.labels.forEach((label, index) => {
              const month = label.slice(2, 4);
              const date = label.slice(4);
              label = `${month}月${date}日`;
              data.labels.push(label);
            });
            break;
          default:
            break;
        }
        diffPerformance.data.forEach((item, index) => {
          const obj = {
            label: item.label,
            backgroundColor: vm.color[index],
            borderColor: vm.color[index],
            pointBackgroundColor: vm.color[index],
            borderWidth: 1,
            pointBorderColor: 'white',
            lineTension: 0,
            data: item.data,
          };
          data.datasets.push(obj);
        });
      }
      return data;
    },
    filter_diff_member() {
      const vm = this;
      const { diffMember } = vm.dashboard;
      const data = {
        labels: [],
        datasets: [],
      };
      if (diffMember.labels && diffMember.labels.length > 0) {
        switch (vm.search.mode) {
          case 'month':
            diffMember.labels.forEach((label, index) => {
              const month = label.slice(3);
              label = `${month}月`;
              data.labels.push(label);
            });
            break;
          case 'week':
            diffMember.labels.forEach((label, index) => {
              const week = label.slice(1, 2);
              label = `第${week}週`;
              data.labels.push(label);
            });
            break;
          case 'day':
            diffMember.labels.forEach((label, index) => {
              const month = label.slice(2, 4);
              const date = label.slice(4);
              label = `${month}月${date}日`;
              data.labels.push(label);
            });
            break;
          default:
            break;
        }
        diffMember.data.forEach((item, index) => {
          const obj = {
            label: item.label,
            backgroundColor: vm.color[index],
            borderColor: vm.color[index],
            pointBackgroundColor: vm.color[index],
            borderWidth: 1,
            pointBorderColor: 'white',
            lineTension: 0,
            data: item.data,
          };
          data.datasets.push(obj);
        });
      }
      return data;
    },
    filter_diff_order() {
      const vm = this;
      const { diffOrder } = vm.dashboard;
      const data = {
        labels: [],
        datasets: [],
      };
      if (diffOrder.labels && diffOrder.labels.length > 0) {
        switch (vm.search.mode) {
          case 'month':
            diffOrder.labels.forEach((label, index) => {
              const month = label.slice(3);
              label = `${month}月`;
              data.labels.push(label);
            });
            break;
          case 'week':
            diffOrder.labels.forEach((label, index) => {
              const week = label.slice(1, 2);
              label = `第${week}週`;
              data.labels.push(label);
            });
            break;
          case 'day':
            diffOrder.labels.forEach((label, index) => {
              const month = label.slice(2, 4);
              const date = label.slice(4);
              label = `${month}月${date}日`;
              data.labels.push(label);
            });
            break;
          default:
            break;
        }
        diffOrder.data.forEach((item, index) => {
          const obj = {
            label: item.label,
            backgroundColor: vm.color[index],
            borderColor: vm.color[index],
            pointBackgroundColor: vm.color[index],
            borderWidth: 1,
            pointBorderColor: 'white',
            lineTension: 0,
            data: item.data,
          };
          data.datasets.push(obj);
        });
      }
      return data;
    },
    filter_couponcode_list() {
      const vm = this;
      const data = vm.dashboard.eventCoupon || [];
      return data.filter((item) => item.systemCode === vm.search.system_code);
    },
    filter_saleevent_list() {
      const vm = this;
      const data = vm.dashboard.eventSales || [];
      return data.filter((item) => item.systemCode === vm.search.system_code);
    },
    filter_percent_location() {
      const vm = this;
      const { percentLocation } = vm.dashboard;
      const bgcData = [];
      const data = {
        labels: [],
        datasets: [],
        total: 0,
        all_data: [],
      };
      if (percentLocation.label && percentLocation.label.length > 0) {
        data.labels = percentLocation.label;
        percentLocation.data.forEach((item) => {
          data.total += Number(item);
        });
        data.labels.forEach((item, index) => {
          const obj = {
            location: item,
            percentage: 0,
          };
          bgcData.push(vm.color[index]);
          data.all_data.push(obj);
        });
        percentLocation.data.forEach((item, index) => {
          data.all_data[index].percentage = ((item / data.total) * 100).toFixed(1);
        });
        data.datasets.push({
          backgroundColor: bgcData,
          data: percentLocation.data,
        });
      }
      return data;
    },
    filter_leader_board() {
      const vm = this;
      const { leaderBoardProduct } = vm.dashboard;
      const data = {
        all_data: [],
      };
      if (leaderBoardProduct && leaderBoardProduct.length > 0) {
        leaderBoardProduct.forEach((item, index) => {
          data.all_data.push(item);
        });
      }
      return data;
    },
    filter_percent_category() {
      const vm = this;
      const { percentCategory } = vm.dashboard;
      const bgcData = [];
      const data = {
        labels: [],
        datasets: [],
        total: 0,
        all_data: [],
      };
      if (percentCategory.label && percentCategory.label.length > 0) {
        data.labels = percentCategory.label;
        percentCategory.data.forEach((item) => {
          data.total += Number(item);
        });
        data.labels.forEach((item, index) => {
          const obj = {
            category: item,
            percentage: 0,
            amount: 0,
          };
          bgcData.push(vm.color[index]);
          data.all_data.push(obj);
        });
        percentCategory.data.forEach((item, index) => {
          data.all_data[index].percentage = ((item / data.total) * 100).toFixed(1);
          data.all_data[index].amount = item;
        });
        data.datasets.push({
          backgroundColor: bgcData,
          data: percentCategory.data,
        });
      }
      return data;
    },
  },
  async mounted() {
    const vm = this;
    this.loaded = true;
    await vm.$store.dispatch('page_load', true);
    await vm.get_system();
    await vm.get_dashboard();
    await vm.$store.dispatch('page_load', false);
  },
  methods: {
    ...mapActions('system', ['get_system']),
    /* 取得 dashboard 資料 */
    async get_dashboard() {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const data = {
        systemCode: vm.search.system_code,
        mode: vm.search.mode,
        dtStart: vm.search.dateStart,
        dtEnd: vm.search.dateEnd,
      };
      const response = await API_GET_DASHBOARD(data);
      const res = response.data;
      if (res.code === '0000') {
        vm.dashboard = res.data;
      }
      await vm.$store.dispatch('page_load', false);
    },
  },
};
</script>
